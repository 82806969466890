<template>
    <div class="eatXfbmReplaceRatio">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="['save','delete','output','refresh','goBack']"
                @saveClick="saveData()" 
                @deleteClick="del()" 
                @outputClick="exportExcel()" 
                @refreshClick="loadData()" 
                @goBackClick="$router.back()"/>
        </div>
        <div class="data-box">
            <div class="flex-box">
                <div class="left-table table-box">
                    <el-tree
                        ref="treeRef"
                        class="filter-tree"
                        :data="treeData"
                        :expand-on-click-node="false"
                        node-key="id"
                        highlight-current
                        default-expand-all
                        @current-change="currentChange"
                    />
                </div>
                <div class="right-table">
                    <div class="filter-box">
                        <div class="from-box">
                            <div class="lable-txt">状态:</div>
                            <div class="from-input" style="width: 180px;">
                                <el-select v-model="status">
                                    <el-option :value="0" label="全部"></el-option>
                                    <el-option :value="1" label="已设置"></el-option>
                                    <el-option :value="2" label="未设置"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="table-box">
                        <el-table class="el-table--scrollable-y" style="width: 100%;height:100%" 
                            ref="tableEl"
                            border
                            highlight-current-row
                            @selection-change="selectFood"
                            v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                            :data="pageTableData"
                        >
                            <el-table-column label="选择" type="selection" width="40" ></el-table-column>
                            <el-table-column prop="Food_Code" label="副菜品编码" min-width="100" align="left"></el-table-column>
                            <el-table-column prop="Food_Name" label="副菜品名称" min-width="100" align="left"></el-table-column>
                            <el-table-column prop="Food_Size" label="单位" min-width="80" align="left" ></el-table-column>
                            <el-table-column label="关联扣库主菜品" min-width="120" align="left">
                                <template #default="scope">
                                    <template v-if="scope.isExcel">{{scope.row.Eat_ReplaceXFBMNAME}}</template>
                                    <select-tree v-else v-model="editFoodList[scope.row.Food_ID].Eat_ReplaceXFBMID" placeholder="请选择" 
                                        :isMultiple="false" :data="treeFoodData" 
                                        searchPlaceholder="菜品模糊检索"
                                        is-search
                                        :filter-node-method="filterNode"
                                        @update:modelValue="rowSelect(scope.row);"
                                        @click="$refs.tableEl?.setCurrentRow(scope.row)"
                                        title="扣库主菜品选择" />
                                </template>
                            </el-table-column>
                            <el-table-column label="扣减系数" min-width="130" align="right" data-format="number">
                                <template #header>
                                    <el-tooltip  effect="dark" content="副菜品售卖份数*扣减系数=主莱品扣减限量份数，0代表不扣减" placement="top">
                                        <div class="tip">扣减系数
                                            <svg class="icon-svg" aria-hidden="true">
                                                <use xlink:href="#icon-infoFilled"></use>
                                            </svg>
                                        </div>
                                    </el-tooltip>
                                </template>
                                <template #default="scope">
                                    <template v-if="scope.isExcel">{{scope.row.WMRatio}}</template>
                                    <div class="num" v-else>
                                        <input-pattern class="wmRatio" :data-id="scope.row.Food_ID" v-show="editFoodList[scope.row.Food_ID].Eat_ReplaceXFBMID"
                                            v-model="editFoodList[scope.row.Food_ID].WMRatio" pattern="number" :min="0" :dplaces="2"
                                            @change="rowSelect(scope.row)"
                                            @keydown.enter="next('.wmRatio',scope.$index+1)"
                                            @focus="$refs.tableEl?.setCurrentRow(scope.row)"
                                            @blur="editFoodList[scope.row.Food_ID].WMRatio==''?(editFoodList[scope.row.Food_ID].WMRatio=0):''"
                                        ></input-pattern>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="LASTUPDATED_USER" label="最后更新人" min-width="100" align="left"></el-table-column> 
                            <el-table-column prop="LASTUPDATED_TIME" label="最后更新时间" min-width="130" :formatter="dateFormat" align="left"></el-table-column> 
                        </el-table>
                    </div>
                    <div class="search-box">
                        <key-board-input class="search"
                            type="text" v-model="searchTxt" placeholder="菜品名称、编码或助记码模糊检索"
                            isKeyEnterClose isBlurClose
                            :keyOptions="{isKeyDownEnter:true}"
                            @keydown.enter="onEnter()"
                        ></key-board-input>
                        <div class="float-right">
                            <span class="last-page iconfont icon-fangxiangquan-left" @click="billPage()"></span>
                            <span class="page-number">{{ tablePageIndex+1 }}/{{ tablePageSum }}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" @click="billPage(1)" ></span>
                            <div class="page-bnt">
                                <el-select v-model="pageSize"  >
                                    <el-option :value="20" label="20条/页"></el-option>
                                    <el-option :value="30" label="30条/页"></el-option>
                                    <el-option :value="50" label="50条/页"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {deepClone} from '/src/common'
/**主副菜关联设置 */
export default {
    name:"eatXfbmReplaceRatio",
    data() {
        let pageSize= this.$cacheData.get("eatXfbmReplaceRatio_pageSize")||20;
        return {
            //选中的节点
            currentNode:null,
            foodMainCategory:[],
            tablePageIndex:0,
            pageSize:pageSize,
            //搜索数据
            searchTxt:"",
            //状态 1已设置 2未设置 0全部
            status:0,
            //主副菜关联数据
            eatXfbmReplaceList:[],
            /**勾选要修改的数据 */
            checkFoods:[],
            /**修改的数据 */
            editFoodList:{},
        }
    },
    computed:{
        /**选择 关联扣库主菜品*/
        treeFoodData(){
            let tree=[];
            this.foodMainCategory?.forEach(category=>{
                let children=[];
                category.FoodSubCategorys?.forEach(sub=>{
                    //Is_SetMeal 排除套餐数据
                    if(sub.PosFoods?.some(f=>!f.Is_SetMeal)){
                        let posFoods=sub.PosFoods.filter(it=>it.Is_Limited);
                        if(posFoods.length>0){//必须是 可限量售卖属性的菜品
                            children.push({
                                id:sub.SubCategory_ID,
                                name:sub.SubCategory_Code+"-"+sub.SubCategory_Name,
                                SubCategory_ID:sub.SubCategory_ID,
                                children:posFoods.map(it=>{
                                    return {
                                        id:it.Food_ID,
                                        name:it.Food_Name,
                                        Food_Code:it.Food_Code,
                                        Food_Name:it.Food_Name,
                                        Food_Size:it.Food_Size,
                                        Food_PinYin:it.Food_PinYin,
                                    }
                                })
                            })
                        }
                    }
                });
                if(children.length>0){
                    tree.push({
                        id:category.MainCategory_ID,
                        name:category.MainCategory_Code+"-"+category.MainCategory_Name,
                        children:children
                    });
                }
            })
            return tree;
        },
        treeData(){
            let tree=[];
            this.foodMainCategory?.forEach(category=>{
                let children=[];
                category.FoodSubCategorys?.forEach(sub=>{
                    //Is_SetMeal 排除套餐数据
                    if(sub.PosFoods?.some(f=>!f.Is_SetMeal)){
                        children.push({
                            id:sub.SubCategory_ID,
                            label:sub.SubCategory_Code+"-"+sub.SubCategory_Name,
                            SubCategory_ID:sub.SubCategory_ID,
                            //PosFoods:sub.PosFoods
                        })
                    }
                });
                if(children.length>0){
                    tree.push({
                        id:category.MainCategory_ID,
                        label:category.MainCategory_Code+"-"+category.MainCategory_Name,
                        children:children
                    });
                }
            })
            return [{label:"全部",children:tree,id:0}];
        },
        /**所有菜品 */
        PosFoods(){
            let posFoods=[];
            this.foodMainCategory?.forEach(category=>{
                category.FoodSubCategorys?.forEach(sub=>{
                    for(let i=0;i<sub.PosFoods.length;i++){
                        let it=sub.PosFoods[i];
                        posFoods.push(it);
                        if(it.MultipleSizeFoods?.length>0){//多规格
                            it.MultipleSizeFoods.forEach(it=>{
                                sub.PosFoods.splice(i+1, 0,it);
                                if(it.Food_ID!=it.Food_ID){
                                    posFoods.push(it);
                                }
                            })
                        }
                    }
                });
            })
            return posFoods;
        },
        tableData(){
            let posFoods=[];
            if(!this.currentNode){
                posFoods=this.PosFoods;
            }
            else if(this.currentNode?.children){
                let foods=[];
                let category= this.foodMainCategory.find(t=>t.MainCategory_ID==this.currentNode.id);
                if(category){
                    category.FoodSubCategorys?.forEach(f=>{
                        foods=foods.concat(f.PosFoods);
                    });
                }
                posFoods= foods;
            }else{
                posFoods=this.PosFoods.filter(t=>t.SubCategory_ID==this.currentNode.id);
            }

            posFoods?.forEach(it=>{
                it.isBindCard=this.foodCards?.some(card=>card.EAT_XFBMID==it.Food_ID); 
            });
            return posFoods.filter(t=>!t.Is_SetMeal);//排除套餐菜品
        },
        filterData(){
            let data=this.tableData||[];
            if(this.searchTxt.trim()!=''){
                let MenuTxt=this.searchTxt.trim().toLowerCase();
                data=data.filter(t=>(t.Food_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0);
            }
            if(this.status>0){
                if(this.status==1){
                    data=data.filter(it=>it.Autoid);
                }else{
                    data=data.filter(it=>!it.Autoid);
                }
                
            }
            return data;
        },
        /**分页数据 */
        pageTableData(){
            return this.filterData.slice(this.tablePageIndex*this.pageSize,(this.tablePageIndex+1)*this.pageSize);
        },
        tablePageSum(){
            let length=this.filterData.length;
            if(length>1){
                return Math.ceil(length/this.pageSize);
            }
            return 1;
        },
        foodList(){
            let list={};
            this.eatXfbmReplaceList?.forEach(it=>{
                list[it.EAT_XFBMID]=it;
            })
            return list
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("eatXfbmReplaceRatio_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
        searchTxt(){
            this.tablePageIndex=0;
        },
        pageTableData:{
            handler(){
                this.updateEditFood();
            },
            immediate:true,
        },
        foodList(){
            this.updateEditFood();
        }
    },
    mounted(){
        this.$emit("navIndex",4);
        const p1=this.$cacheData.AllPosFoods().then((d)=>{
            this.currentNode=null;
            this.foodMainCategory=deepClone(d||[]);
            this.mergeTable();
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        const loading = this.$loading({
            text: "数据加载中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        Promise.all([p1,this.loadData(false)]).then(data=>{
            console.log(data)
            loading.close();
        }).catch(err=>{
            console.log(err) // 失败 
        })
    },
    methods:{
        /**合并数据 */
        mergeTable(){
            (this.PosFoods||[]).forEach(it=>{
                Object.assign(it,this.foodList[it.Food_ID]);
                if(it.Eat_ReplaceXFBMID){
                    it.Eat_ReplaceXFBMNAME=this.PosFoods.find(f=>f.Food_ID==it.Eat_ReplaceXFBMID)?.Food_Name||"";
                }
            })
        },
        /**更新 可修改的数据记录 */
        updateEditFood(){
            let list={};
            this.pageTableData.forEach(it=>{
                list[it.Food_ID]=Object.assign({},this.foodList[it.Food_ID]);
            });
            this.editFoodList=list;
        },
        loadData(isLoaing){
            let loading
            if(isLoaing!==false){
                loading = this.$loading({
                    text: "数据加载中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
            return this.$httpAES.post("Bestech.CloudPos.GetEatXfbmReplaceRatio", {}).then((d)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    let data=d.ResponseBody;
                    this.eatXfbmReplaceList=data;
                    this.mergeTable();
                }
            }).catch((e)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                this.$alert("数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        currentChange(node){
            if(node.id!=0){
                this.currentNode=node;
            }else{
                this.currentNode=null;
            }
            
        },
        //下一个 选中
        next(className,index){
            if(index<this.pageTableData.length){
                this.$el.querySelectorAll(className+"[data-id]")[index]?.focus();
            }
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        /**搜索 */
        filterNode(value, data){
            console.log(value)
            if (!value) return true;
            value=value.toLocaleUpperCase();
            return data.Food_Code?.toLocaleUpperCase().includes(value) || data.Food_Name?.toLocaleUpperCase().includes(value) || data.Food_PinYin?.toLocaleUpperCase().includes(value);
        },
        //勾选的菜品
        selectFood(currentRow){
            this.checkFoods=currentRow;
        },
         /**修改时 勾选行 */
        rowSelect(row){
            this.$refs.tableEl?.toggleRowSelection(row,true);
        },
        /**删除 */
        del(){
            let EatXfbmList=[];
            this.checkFoods?.forEach(it=>{
                let editInfo=this.editFoodList[it.Food_ID];
                if(editInfo){
                    EatXfbmList.push({
                        EAT_XFBMID:it.Food_ID,
                    });
                }
            })
            if(EatXfbmList.length==0){
                this.$message.warning('请勾选需要删除的数据');
                return;
            }

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let data={
                Operator_Name:userInfo?.Login_Name,
                Eat_XFBMReplaceRatioList:EatXfbmList
            }
            this.$httpAES.post("Bestech.CloudPos.SetEatXfbmReplaceRatio",data).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据删除成功");
                    this.$refs.tableEl?.clearSelection()
                    EatXfbmList.forEach(it=>{
                        let index=this.eatXfbmReplaceList?.findIndex(food=>food.EAT_XFBMID==it.EAT_XFBMID)
                        if(index>=0){//删除
                            this.eatXfbmReplaceList.splice(index,1);
                        }
                        this.PosFoods.find(food=>{
                            if(food.Food_ID==it.EAT_XFBMID){
                                food.EAT_XFBMID="";
                                food.Eat_ReplaceXFBMID="";
                                food.Eat_ReplaceXFBMNAME="";
                                food.TSRatio="";
                                food.WMRatio="";
                                food.LASTUPDATED_USER="";
                                food.LASTUPDATED_TIME="";
                                return true;
                            }   
                            return false;
                        })
                    })
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据删除失败："+e);
                console.log("数据删除失败：",e);
            })
        },
        /**保存修改 */
        saveData(){
            let EatXfbmList=[];
            this.checkFoods?.forEach(it=>{
                let editInfo=this.editFoodList[it.Food_ID];
                if(editInfo){
                    EatXfbmList.push({
                        EAT_XFBMID:it.Food_ID,
                        Eat_ReplaceXFBMID:editInfo.Eat_ReplaceXFBMID,
                        TSRatio:editInfo.WMRatio>=0?Number(editInfo.WMRatio):0,
                        WMRatio:editInfo.WMRatio>=0?Number(editInfo.WMRatio):0,
                    });
                }
            })
            if(EatXfbmList.length==0){
                this.$message.warning('请勾选需要保存的数据');
                return;
            }

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let data={
                Operator_Name:userInfo?.Login_Name,
                Eat_XFBMReplaceRatioList:EatXfbmList
            }
            this.$httpAES.post("Bestech.CloudPos.SetEatXfbmReplaceRatio",data).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据保存成功");
                    this.$refs.tableEl?.clearSelection()
                    d.ResponseBody?.forEach(it=>{
                        let index=this.eatXfbmReplaceList?.findIndex(food=>food.EAT_XFBMID==it.EAT_XFBMID)
                        if(!it.Eat_ReplaceXFBMID){//删除
                            if(index>=0){
                                this.eatXfbmReplaceList.splice(index,1);
                                this.PosFoods.find(food=>{
                                    if(food.Food_ID==it.EAT_XFBMID){
                                        food.EAT_XFBMID="";
                                        food.Eat_ReplaceXFBMID="";
                                        food.Eat_ReplaceXFBMNAME="";
                                        food.TSRatio="";
                                        food.WMRatio="";
                                        food.LASTUPDATED_USER="";
                                        food.LASTUPDATED_TIME="";
                                        return true;
                                    }   
                                    return false;
                                })
                            }
                        }else {//新增或修改
                            if(index>=0){
                                let info=this.eatXfbmReplaceList[index];
                                Object.assign(info,it);
                            }else{
                                if(this.eatXfbmReplaceList?.length>0){
                                    this.eatXfbmReplaceList.push(it)
                                }else{
                                    this.eatXfbmReplaceList=[it];
                                }
                            }
                            this.mergeTable();
                        }
                    })
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据保存失败："+e);
                console.log("数据保存失败：",e);
            })
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.tableEl,
                titleName:"主副菜关联设置",
                list:this.filterData
            })
        },
    }
}
</script>

<style lang="scss">
@import './eatXfbmReplaceRatio.scss'
</style>